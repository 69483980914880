import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const BlogPostPage = ({ location, data: { contentfulBlogPost: post } }) => {
  return (
    <Layout>
      <SEO
        pathname={location.pathname}
        title={post.title}
        description={post.content?.childMarkdownRemark.excerpt}
        og={{ type: 'article', published_time: post.createdAt }}
      />

      <div className="relative w-11/12 max-w-screen-md mx-auto py-24">
        <h1 className="font-bold text-2xl text-center mb-24">{post.title}</h1>

        <div dangerouslySetInnerHTML={{ __html: post.content?.childMarkdownRemark.html }} className="rich-text" />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query BlogPostPageQuery($slug: String!, $locale: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      content {
        childMarkdownRemark {
          html
          excerpt(format: PLAIN, pruneLength: 260, truncate: true)
        }
      }
      displayCreatedAt: createdAt(locale: $locale, formatString: "MMMM Do, YYYY")
      createdAt
    }
  }
`;

export default BlogPostPage;
